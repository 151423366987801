<!--  -->
<template>
    <div>
        <el-row>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link" @click="TendencySingo()">
                            <font-awesome-icon icon="fa-solid fa-heart-pulse" size="4x" style="color:firebrick" />
                            <h1>心电检测</h1>
                            <!-- <h1>心电检测<i class="el-icon-arrow-right"></i></h1> -->
                        </span>
                        <!-- <el-dropdown-menu class="dropmenu" slot="dropdown">
                            <el-dropdown-item class="sort-item" @click.native="ReadSingo()">诊断记录查看</el-dropdown-item>
                            <el-dropdown-item class="sort-item" @click.native="TendencySingo()">总体趋势分析</el-dropdown-item>
                        </el-dropdown-menu> -->
                    </el-dropdown>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link"  @click="TendencySingoHolter()">
                            <font-awesome-icon icon="fa-solid fa-circle-h" size="4x" style="color:firebrick" />
                            <h1>24小时Holter </h1>
                        </span>
                        <!-- <el-dropdown-menu class="dropmenu" slot="dropdown">
                            <el-dropdown-item class="sort-item" @click.native="ReadSingoHolter()">诊断记录查看
                            </el-dropdown-item>
                            <el-dropdown-item class="sort-item" @click.native="TendencySingoHolter()">总体趋势分析</el-dropdown-item>
                        </el-dropdown-menu> -->
                    </el-dropdown>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link" @click="ReadEight()">
                            <font-awesome-icon icon="fa-solid fa-file-waveform" size="4x" style="color:firebrick" />
                            <h1>八导联 </h1>
                        </span>
                        <!-- <el-dropdown-menu class="dropmenu" slot="dropdown">
                            <el-dropdown-item class="sort-item" @click.native="ReadEight()">诊断记录查看</el-dropdown-item>
                            <el-dropdown-item class="sort-item">总体趋势分析</el-dropdown-item>
                        </el-dropdown-menu> -->
                    </el-dropdown>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link">
                            <font-awesome-icon icon="fa-solid fa-square-h" size="4x" style="color:firebrick" />
                            <h1>八导联Holter</h1>
                        </span>
                        <!-- <el-dropdown-menu class="dropmenu" slot="dropdown">
                            <el-dropdown-item class="sort-item">诊断记录查看</el-dropdown-item>
                            <el-dropdown-item class="sort-item">总体趋势分析</el-dropdown-item>
                        </el-dropdown-menu> -->
                    </el-dropdown>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link">
                            <font-awesome-icon icon="fa-solid fa-vial" size="4x" style="color:firebrick" />
                            <h1>尿液分析仪</h1>
                        </span>
                        <!-- <el-dropdown-menu class="dropmenu" slot="dropdown">
                            <el-dropdown-item class="sort-item">诊断记录查看</el-dropdown-item>
                            <el-dropdown-item class="sort-item">总体趋势分析</el-dropdown-item>
                        </el-dropdown-menu> -->
                    </el-dropdown>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link">
                            <font-awesome-icon icon="fa-regular fa-circle" size="4x" style="color:firebrick" />
                            <h1>血氧检测仪</h1>
                        </span>
                        <!-- <el-dropdown-menu class="dropmenu" slot="dropdown">
                            <el-dropdown-item class="sort-item">诊断记录查看</el-dropdown-item>
                            <el-dropdown-item class="sort-item">总体趋势分析</el-dropdown-item>
                        </el-dropdown-menu> -->
                    </el-dropdown>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link">
                            <font-awesome-icon icon="fa-solid fa-hard-drive" size="4x" style="color:firebrick" />
                            <h1>血糖检测仪 </h1>
                        </span>
                        <!-- <el-dropdown-menu class="dropmenu" slot="dropdown">
                            <el-dropdown-item class="sort-item">诊断记录查看</el-dropdown-item>
                            <el-dropdown-item class="sort-item">总体趋势分析</el-dropdown-item>
                        </el-dropdown-menu> -->
                    </el-dropdown>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link">
                            <font-awesome-icon icon="fa-solid fa-droplet" size="4x" style="color:firebrick" />
                            <h1>血脂检测仪 </h1>
                        </span>
                        <!-- <el-dropdown-menu class="dropmenu" slot="dropdown">
                            <el-dropdown-item class="sort-item">诊断记录查看</el-dropdown-item>
                            <el-dropdown-item class="sort-item">总体趋势分析</el-dropdown-item>
                        </el-dropdown-menu> -->
                    </el-dropdown>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link">
                            <font-awesome-icon icon="fa-solid fa-stethoscope" size="4x" style="color:firebrick" />
                            <h1>血压检测仪</h1>
                        </span>
                        <!-- <el-dropdown-menu class="dropmenu" slot="dropdown">
                            <el-dropdown-item class="sort-item">诊断记录查看</el-dropdown-item>
                            <el-dropdown-item class="sort-item">总体趋势分析</el-dropdown-item>
                        </el-dropdown-menu> -->
                    </el-dropdown>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link">
                            <font-awesome-icon icon="fa-solid fa-weight-scale" size="4x" style="color:firebrick" />
                            <h1>体脂检测仪</h1>
                        </span>
                        <!-- <el-dropdown-menu class="dropmenu" slot="dropdown">
                            <el-dropdown-item class="sort-item">诊断记录查看</el-dropdown-item>
                            <el-dropdown-item class="sort-item">总体趋势分析</el-dropdown-item>
                        </el-dropdown-menu> -->
                    </el-dropdown>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link">
                            <font-awesome-icon icon="fa-solid fa-temperature-three-quarters" size="4x"
                                style="color:firebrick" />
                            <h1>基础体温 </h1>
                        </span>
                        <!-- <el-dropdown-menu class="dropmenu" slot="dropdown">
                            <el-dropdown-item class="sort-item">诊断记录查看</el-dropdown-item>
                            <el-dropdown-item class="sort-item">总体趋势分析</el-dropdown-item>
                        </el-dropdown-menu> -->
                    </el-dropdown>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link">
                            <font-awesome-icon icon="fa-solid fa-cubes" size="4x" style="color:firebrick" />
                            <h1>三合一检测仪</h1>
                        </span>
                        <!-- <el-dropdown-menu class="dropmenu" slot="dropdown">
                            <el-dropdown-item class="sort-item">诊断记录查看</el-dropdown-item>
                            <el-dropdown-item class="sort-item">总体趋势分析</el-dropdown-item>
                        </el-dropdown-menu> -->
                    </el-dropdown>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link">
                            <font-awesome-icon icon="fa-solid fa-person-breastfeeding" size="4x"
                                style="color:firebrick" />
                            <h1>胎心仪</h1>
                        </span>
                        <!-- <el-dropdown-menu class="dropmenu" slot="dropdown">
                            <el-dropdown-item class="sort-item">诊断记录查看</el-dropdown-item>
                            <el-dropdown-item class="sort-item">总体趋势分析</el-dropdown-item>
                        </el-dropdown-menu> -->
                    </el-dropdown>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="service-box">
                    <el-dropdown placement="right">
                        <span class="el-dropdown-link">
                            <font-awesome-icon icon="fa-solid fa-flask" size="4x" style="color:firebrick" />
                            <h1>生化分析仪 </h1>
                        </span>
                        <!-- <el-dropdown-menu class="dropmenu" slot="dropdown">
                            <el-dropdown-item class="sort-item">诊断记录查看</el-dropdown-item>
                            <el-dropdown-item class="sort-item">总体趋势分析</el-dropdown-item>
                        </el-dropdown-menu> -->
                    </el-dropdown>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {

        };
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        ReadSingo() {
            this.$router.push({ name: 'HistorySingo' })
        },
        ReadSingoHolter() {
            this.$router.push({ name: 'HistorySingoHolter' })
        },
        ReadEight() {
            this.$router.push({ name: 'HistoryEight' })
        },
        TendencySingo() {
            this.$router.push({name:'Tendency_singo_women'})
        },
        TendencySingoHolter() {
            this.$router.push({name:'Tendency_singoHolter_women'})
        }
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style>
.service-box {
    display: inline-block;
    width: 300px;
    height: 145px;
    text-align: center;
}

.el-dropdown-link {
    cursor: pointer;
    color: #252525;
}

.dropmenu {
    width: 240px;
    height: 200px;
    padding: 20px 20px;
}

.sort-item {
    display: inline-block;
    min-width: 110px;
    padding: 10 10px;
    margin: 10px 0px 10px 0;
    border-radius: 16px;
    line-height: 60px;
    font-size: 25px;
    text-align: center;
    cursor: pointer;
    color: dodgerblue
}
</style>